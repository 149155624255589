import { $unwrapMarkNode, $wrapSelectionInMarkNode } from '@lexical/mark';
import { mergeRegister } from '@lexical/utils';
import {
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  CLICK_COMMAND,
} from 'lexical';
import { $getAbbreviationSelectionNode } from './utils';

export const ABBREVIATION_CHANGE = createCommand<string | undefined>();

export const registerCommands = (editor: LexicalEditor, handleClick: () => void): () => void => {

  return mergeRegister(
    editor.registerCommand(
      ABBREVIATION_CHANGE,
      (id) => {

        const selection = $getSelection();
        if (!selection || !$isRangeSelection(selection)) {
          return false;
        }

        if (!id) {
          const node = $getAbbreviationSelectionNode(selection);
          if (node) {
            const [abbreviationId] = node.getIDs();
            editor.update(() => {
              node.deleteID(abbreviationId);
              $unwrapMarkNode(node);
            });
          }
        } else {
          editor.update(() => {
            if ($isRangeSelection(selection)) {
              const isBackward = selection.isBackward();

              // Wrap content in a MarkNode
              $wrapSelectionInMarkNode(selection, isBackward, id);
            }
          });
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),
    editor.registerCommand(
      CLICK_COMMAND,
      () => {

        const selection = $getSelection();
        if (!selection || !$isRangeSelection(selection)) {
          return false;
        }
        const node = $getAbbreviationSelectionNode(selection);
        if (node && selection.getTextContent().length === 0) {
          handleClick();
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),
  );
};
