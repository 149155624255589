import { $isMarkNode, MarkNode } from '@lexical/mark';
import { $getSelection, $isRangeSelection, RangeSelection } from 'lexical';

type GetAbbreviationNode = {
  abbreviationId?: string;
  abbreviationTerm?: string;
};

export const $getAbbreviationSelectionNode = (selection: RangeSelection): MarkNode | null | undefined => {
  const nodes = selection.getNodes();
  const abbreviationNode = nodes.length > 1
    ? nodes.find(node => node.getType() === MarkNode.getType())
    : nodes[0].getParent();

  return abbreviationNode && $isMarkNode(abbreviationNode)
    ? abbreviationNode
    : undefined;
};

export const $getAbbreviationData = (): GetAbbreviationNode => {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return {};
  }

  const node = $getAbbreviationSelectionNode(selection);

  if (!node) {
    return {
      abbreviationId: undefined,
      abbreviationTerm: selection.getTextContent(),
    };
  }

  const [abbreviationId] = node.getIDs();

  return {
    abbreviationId,
    abbreviationTerm: node.getTextContent(),
  };


};
